import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useCallback, useEffect } from 'react';
import content from '../../../../content/showcase/thng/intro.json';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, largeText, pageSubtitle, regularText, smallText } from '../../../../styles/dimensions';
import RigrafItalic from '../../../../fonts/rigraf/italic/rigrafItalic';
import RigrafExpanded from '../../../../fonts/rigraf/expanded/rigrafExpanded';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import { ScrollEvent, useScrollContext } from '../../../../hooks/useScrollContext';
import { StaticImage } from 'gatsby-plugin-image';
// @ts-ignore
import thngTextSVG from '../../../../images/showcase/thng/THNG.svg';

const Intro = () => {
	const scroll = useScrollContext();
	const isThngHidden = useRef<boolean>(false);
	const isImageElementHidden = useRef<boolean>(false);
	const imageContainerRef = useRef<HTMLDivElement | null>(null);

	const classes = useClasses();

	const updateThngElement = useCallback(
		(currentPosition: number, thngElement: HTMLElement) => {
			if (currentPosition > 500 + scroll?.scroll?.windowHeight && !isThngHidden.current) {
				isThngHidden.current = true;
				thngElement.style.opacity = '0';
				thngElement.style.display = 'none';
				scroll.update();
			} else if (currentPosition <= 500 + scroll?.scroll?.windowHeight && isThngHidden.current) {
				isThngHidden.current = false;
				thngElement.style.opacity = '1';
				thngElement.style.display = 'inline';
				scroll.update();
			}
		},
		[scroll]
	);

	const updateImageElement = useCallback(
		(currentPosition: number, visibleImageElement: HTMLElement) => {
			if (currentPosition > 300 + scroll?.scroll?.windowHeight && !isImageElementHidden.current) {
				isImageElementHidden.current = true;
				visibleImageElement.style.opacity = '0';
				visibleImageElement.style.transitionDelay = '0s';
				scroll.update();
			} else if (currentPosition <= 300 + scroll?.scroll?.windowHeight && isImageElementHidden.current) {
				isImageElementHidden.current = false;
				visibleImageElement.style.opacity = '1';
				visibleImageElement.style.transitionDelay = '0.35s';
				scroll.update();
			}
		},
		[scroll]
	);
	const appendTranslate = useCallback((currentPosition: number, thngElement: HTMLElement) => {
		if (thngElement) {
			if (currentPosition <= 300) {
				thngElement.style.transform = `translate(calc(-50% - ${
					currentPosition * 0.018
				}%), calc(-50% + ${currentPosition}px))`;
			} else {
				thngElement.style.transform = `translate(calc(-50% - (300 * 0.075%)), calc(-50% + ${currentPosition}px)) scale(${
					currentPosition / (currentPosition * 0.25)
				})`;
			}
		}
	}, []);

	useEffect(() => {
		if (scroll?.scroll?.windowHeight >= 600) {
			const thngElement = imageContainerRef.current.children[1] as HTMLElement;
			const visibleImageElement = imageContainerRef.current.firstElementChild as HTMLElement;
			scroll.on('scroll', (event: ScrollEvent) => {
				updateThngElement(event.scroll.y, thngElement);
				if (!isThngHidden.current) {
					appendTranslate(event.scroll.y, thngElement);
					thngElement.style.width = `calc(73px + ${event.scroll.y}vw)`;
				}
				appendTranslate(event.scroll.y, thngElement);
				thngElement.style.width = `calc(73px + ${event.scroll.y}vw)`;
				updateImageElement(event.scroll.y, visibleImageElement);
			});
		}
		return () => {
			if (scroll?.scroll?.windowHeight >= 600) {
				// document.body.onscroll = null;
				scroll.destroy();
			}
		};
	}, [scroll, appendTranslate, updateThngElement, updateImageElement]);
	return (
		<section className={classes.section}>
			<div className={classes.imageContainer} ref={imageContainerRef}>
				<StaticImage
					src="../../../../images/showcase/thng/iphone_mockup_thng.png"
					alt=""
					className={classes.image}
					id="visibleImageElement"
					layout="constrained"
					loading="eager"
					placeholder="tracedSVG"
					aria-hidden="true"
				/>
				<img src={thngTextSVG} alt="THNG" className={classes.thngText} />
				<StaticImage
					src="../../../../images/showcase/thng/thng_mockup.png"
					alt="THNG"
					className={classes.thngMockup}
					layout="constrained"
					loading="eager"
					placeholder="tracedSVG"
				/>
			</div>
			<div className={classes.gridContainer}>
				<Grid className={classes.grid} data-scroll data-scroll-speed="2">
					<Grid item md={1}></Grid>
					<Grid item xs={12} md={10}>
						<blockquote className={classes.blockquote}>
							<p className={classes.quoteDescription}>{content.quote.description}</p>
							<p className={classes.author}>{content.quote.author}</p>
						</blockquote>
						<Grid item md={2}></Grid>
						<div className={classes.detailsContainer}>
							{content.quote.details.map((detail, key) => (
								<div key={key} className={classes.detailsItemContainer}>
									<h6 className={classes.detailsName}>{detail.name}</h6>
									<span className={classes.detailsDescription}>{detail.description}</span>
								</div>
							))}
						</div>
					</Grid>
				</Grid>
			</div>
		</section>
	);
};
export default Intro;
const useClasses = makeStyles({
	section: {
		paddingTop: 'calc(7.625em + 2px)',
		boxSizing: 'border-box',
		'@media (max-width: 599px)': {
			paddingTop: 'calc(3.75em + 2px)',
		},
	},
	imageContainer: {
		height: 'calc(100vh + 400px)',
		'@media (max-width: 599px)': {
			height: '180px',
		},
	},
	image: {
		// @ts-ignore
		position: 'fixed !important',
		left: '50%',
		top: '50vh',
		transform: 'translate(-50%, -50%)',
		transition: 'opacity 0s linear',
		pointerEvents: 'none',
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	thngText: {
		// @ts-ignore
		position: 'absolute !important',
		zIndex: 2,
		left: '50%',
		top: '55vh',
		transition: 'opacity 0.35s linear',
		pointerEvents: 'none',
		overflow: 'hidden',
		maxWidth: 'unset',
		transform: `translate(calc(-50%), calc(-50%))`,
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	thngMockup: {
		marginTop: '1.625em',
		position: 'relative',
		left: '50%',
		transform: 'translateX(-50%)',
		'@media (min-width: 600px)': {
			display: 'none !important',
		},
	},
	gridContainer: {
		zIndex: 3,
		backgroundColor: colors.thngBlue,
		paddingTop: 'calc(7.625em + 2px + 5.75em + 300px)',
		paddingBottom: 'calc(4.5em + 50px)',
		marginTop: '-100px',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 599px)': {
			marginTop: 0,
			paddingTop: 'calc(180px + 4em)',
			paddingBottom: '1.25em',
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	blockquote: {
		margin: 0,
	},
	quoteDescription: {
		fontFamily: RigrafItalic.extraLight,
		fontSize: pageSubtitle.fontSize,
		lineHeight: pageSubtitle.lineHeight,
		color: colors.white,
		marginTop: 0,
		marginBottom: '0.9em',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '1.2em',
		},
	},
	author: {
		fontFamily: RigrafExpanded.semiBold,
		fontSize: largeText.fontSize,
		lineHeight: largeText.lineHeight,
		color: colors.white,
		marginTop: '1.5em',
		marginBottom: '4.333333333333333em',
		'@media (max-width: 599px)': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			marginBottom: '4.25em',
		},
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'row',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
		},
	},
	detailsItemContainer: {
		width: 'calc(25% * 12 / 10)',
		'@media (max-width: 599px)': {
			marginBottom: '2em',
			width: 'auto',
		},
	},
	detailsName: {
		color: colors.white,
		marginTop: 0,
		marginBottom: '0.25em',
	},
	detailsDescription: {
		color: colors.whiteDimmed,
		'@media (max-width: 599px)': {
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
		},
	},
});
