import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../../content/showcase/thng/easyUse.json';
import { defaultSectionWidth, regularText, smallText } from '../../../../styles/dimensions';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import { Carousel } from 'react-responsive-carousel';
import { StaticImage } from 'gatsby-plugin-image';
import colors from '../../../../styles/colors';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const EasyUse = () => {
	const classes = useClasses();

	return (
		<section className={classes.section} data-scroll data-scroll-speed="2">
			<Grid container>
				<Grid item xs={12} md={4}>
					<h2 className={classes.header}>{content.title}</h2>

					<StaticImage
						src="../../../../images/showcase/thng/showcase_man_photo.jpg"
						alt=""
						className={classes.imageMale}
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						aria-hidden="true"
					/>

					<div className={classes.sliderContainer}>
						<StaticImage
							src="../../../../images/showcase/thng/mockup_iphone_grey_cut.png"
							alt=""
							className={classes.frame}
							layout="constrained"
							loading="lazy"
							placeholder="tracedSVG"
							objectFit="contain"
							aria-hidden="true"
						/>

						<Carousel
							dynamicHeight={false}
							emulateTouch={true}
							autoPlay={false}
							infiniteLoop={true}
							centerMode={false}
							showArrows={false}
							showStatus={false}
							showIndicators={false}
							showThumbs={false}
							swipeable={true}
							className={classes.carousel}>
							<StaticImage
								src="../../../../images/showcase/thng/screen_1.png"
								alt="Welcome screen"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
							<StaticImage
								src="../../../../images/showcase/thng/screen_2.png"
								alt="Registration"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
							<StaticImage
								src="../../../../images/showcase/thng/screen_3.png"
								alt="First steps"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
							<StaticImage
								src="../../../../images/showcase/thng/screen_4.png"
								alt="Successful registration"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
							<StaticImage
								src="../../../../images/showcase/thng/screen_5.png"
								alt="Previous surveys"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
							<StaticImage
								src="../../../../images/showcase/thng/screen_6.png"
								alt="Survey"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
							<StaticImage
								src="../../../../images/showcase/thng/screen_7.png"
								alt="Current surveys"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
						</Carousel>
						<p className={classes.whitelabelScreens}>*Whitelabel screens</p>
					</div>

					{content.features.map((feature, key) => (
						<div key={key} className={classes.feature}>
							<h4 className={classes.subHeader}>{feature.name}</h4>
							<p className={classes.description}>{feature.description}</p>
						</div>
					))}
				</Grid>

				<Grid item md={2}></Grid>

				<Grid item xs={12} md={6} className={classes.mockupsColumn}>
					<StaticImage
						src="../../../../images/showcase/thng/showcase_iphone_mockups.png"
						alt="THNG application screens"
						layout="constrained"
						loading="lazy"
						placeholder="tracedSVG"
						className={classes.iphoneMockups}
					/>
					<StaticImage
						src="../../../../images/showcase/thng/ellipse.svg"
						alt=""
						layout="constrained"
						loading="lazy"
						placeholder="tracedSVG"
						width={740}
						height={740}
						aria-hidden="true"
						className={classes.ellipse}
					/>
				</Grid>
			</Grid>
		</section>
	);
};

export default EasyUse;

const useClasses = makeStyles({
	whitelabelScreens: {
		color: colors.lightText,
		paddingBottom: '2em',
		textAlign: 'center',
		'@media (min-width: 599px)': {
			display: 'none',
		},
	},
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',

		paddingBottom: '5.5em',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 599px)': {
			paddingTop: '3.5em',
			paddingBottom: '4.25em',
		},
	},
	header: {
		marginTop: 0,
		marginBottom: '1.4em',
		color: colors.white,
		'@media (max-width: 599px)': {
			maxWidth: '75%',
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '1.1em',
		},
		'@media (min-width: 1200px)': {
			maxWidth: '90%',
		},
	},
	subHeader: {
		marginTop: 0,
		marginBottom: '0.6666666666666666em',
		color: colors.white,
		'@media (max-width: 599px)': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			marginBottom: '0.5em',
		},
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		marginTop: '1em',
		marginBottom: 0,
		color: colors.careersJobGray,
		'@media (max-width: 599px)': {
			marginTop: '0.5em',
		},
	},
	feature: {
		marginBottom: '2.25em',
		'@media (max-width: 599px)': {
			marginBottom: '2em',
		},
	},
	iphoneMockups: {
		position: 'relative',
		zIndex: 2,
		'@media (min-width: 600px) and (max-width: 959px)': {
			left: '50%',
			transform: 'translateX(-50%)',
		},
	},
	ellipse: {
		// @ts-ignore
		position: 'absolute !important',
		zIndex: -1,
		top: '50%',
		'@media (min-width: 960px) ': {
			right: 0,
			transform: 'translate(5.83333333%, -50%)',
		},
		'@media (min-width: 960px) and (max-width: 1231px)': {
			width: 'calc((100vw / 1232) * 740) !important',
			height: 'calc((100vw / 1232) * 740) !important',
		},
		'@media (max-width: 959px) ': {
			width: '100vw',
			height: '100vw',
			left: 0,
			bottom: '50%',
			transform: 'translateY(-25%)',
		},
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	imageMale: {
		maxWidth: 'unset',
		width: '100vw',
		transform: 'translateX(-1em)',
		marginBottom: '3.25em',
		'@media (min-width: 600px)': {
			display: 'none !important',
		},
	},
	mockupsColumn: {
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	frame: {
		padding: '0 15%',
		boxSizing: 'border-box',
		marginBottom: '1.25em',
		// maxHeight: '475px',
		'@media (min-width: 600px)': {
			display: 'none !important',
		},
	},
	carousel: {
		position: 'absolute',
		width: '58%',
		top: '5.8%',
		left: '50%',
		transform: 'translateX(-50%)',
		'& .slider .slide': {
			backgroundColor: 'transparent',
		},
		'@media (min-width: 600px)': {
			display: 'none',
		},
	},
	sliderContainer: {
		position: 'relative',
		'@media (min-width: 600px)': {
			display: 'none',
		},
	},
});
