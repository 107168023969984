import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../../content/showcase/thng/professionalService.json';
import RigrafExpanded from '../../../../fonts/rigraf/expanded/rigrafExpanded';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, regularText, smallSubtitle } from '../../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';

const ProfessionalService = () => {
	const classes = useClasses();

	return (
		<section className={classes.section} data-scroll data-scroll-speed="2">
			<Grid container className={classes.grid}>
				<Grid item xs={12} sm={6} className={classes.headerColumn}>
					<h1 className={classes.header}>{content.header.title}</h1>
				</Grid>

				<Grid item sm={1}></Grid>

				<Grid item xs={12} sm={5} className={classes.descriptionColumn}>
					<p className={classes.description}>{content.header.description}</p>
				</Grid>

				<Grid item xs={12} sm={6} className={classes.imageColumnFemale}>
					<StaticImage
						src="../../../../images/showcase/thng/showcase_woman_photo.jpg"
						alt=""
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.imageFemale}
						aria-hidden="true"
					/>
				</Grid>

				<Grid item xs={12} sm={6} className={classes.imageColumnMale}>
					<StaticImage
						src="../../../../images/showcase/thng/showcase_man_photo.jpg"
						alt=""
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.imageMale}
						aria-hidden="true"
					/>
				</Grid>
			</Grid>
		</section>
	);
};

export default ProfessionalService;

const useClasses = makeStyles({
	section: {
		paddingTop: '3.75em',
		'@media (max-width: 599px)': {
			paddingTop: '2.5em',
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	header: {
		margin: 0,
		fontFamily: RigrafExpanded.semiBold,
		fontWeight: 'normal',
		fontSize: smallSubtitle.fontSize,
		lineHeight: smallSubtitle.lineHeight,
		color: colors.white,
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			maxWidth: '75%',
			marginBottom: '1.2em',
		},
	},
	description: {
		margin: 0,
		fontFamily: MetroSans.book,
		color: colors.careersJobGray,
	},
	headerColumn: {
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 599px)': {
			order: 0,
		},
	},
	descriptionColumn: {
		paddingLeft: '1em',
		paddingRight: '1em',

		'@media (max-width: 599px)': {
			order: 2,
		},
		'@media (min-width: 600px)': {
			marginBottom: '8.25em',
		},
	},
	imageColumnFemale: {
		'@media (max-width: 599px)': {
			order: 1,
			marginBottom: '1.5em',
		},
	},
	imageColumnMale: {
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	imageFemale: {
		'@media (min-width: 1200px)': {
			maxWidth: 'unset',
			width: '50vw',
			position: 'absolute !important',
			left: 0,
		},
	},
	imageMale: {
		'@media (min-width: 1200px)': {
			maxWidth: 'unset',
			width: '50vw',
		},
	},
});
