import { makeStyles } from '@material-ui/core';
import Layout from '../../components/layout';
import EasyUse from '../../components/sections/showcase/thng/easyUse';
import Intro from '../../components/sections/showcase/thng/intro';
import ProfessionalService from '../../components/sections/showcase/thng/professionalService';
import Vote from '../../components/sections/showcase/thng/vote';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import colors from '../../styles/colors';

const Thng = () => {
	const classes = useClasses();

	return (
		<Layout rootClassName={classes.root}>
			<SEO title={titles.showcase.THNG} description={descriptions.showcase.THNG} />
			<Intro />
			<ProfessionalService />
			<EasyUse />
			<Vote />
		</Layout>
	);
};

export default Thng;

const useClasses = makeStyles({
	root: {
		backgroundColor: colors.backgroundDarkGray,
	},
});
