import { Grid, makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';
import colors from '../../../../styles/colors';
import content from '../../../../content/showcase/thng/vote.json';
import { defaultSectionWidth, smallText } from '../../../../styles/dimensions';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { StaticImage } from 'gatsby-plugin-image';

interface ClassProps {
	visible: boolean;
}

const Vote = () => {
	const [visible, setVisible] = useState(false);

	const classes = useClasses({ visible });

	const handleVisibilityChange = useCallback((isVisible: boolean) => {
		setVisible(isVisible);
	}, []);

	return (
		<section className={classes.section}>
			<Grid container className={classes.grid}>
				<Grid item xs={12} md={6}>
					<div className={classes.imageContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../../images/showcase/thng/showcase_iphone_mockups_2.png"
							alt="THNG application screens"
							className={classes.image}
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
						/>
					</div>
					<StaticImage
						src="../../../../images/showcase/thng/statistic_circle.svg"
						alt=""
						className={classes.statisticCircle}
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						aria-hidden="true"
					/>
					<StaticImage
						src="../../../../images/showcase/thng/statistic_content_table.svg"
						alt=""
						className={classes.statisticTable}
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						aria-hidden="true"
					/>
				</Grid>

				<Grid item md={1}></Grid>

				<Grid item xs={12} md={4}>
					<h3 className={classes.header} data-scroll data-scroll-speed="1">
						{content.title}
					</h3>
					<div>
						<h4 className={classes.subheader} data-scroll data-scroll-speed="1">
							{content.details.name}
						</h4>
						<p className={classes.description} data-scroll data-scroll-speed="1">
							{content.details.description1}
						</p>
						<p className={classes.description} data-scroll data-scroll-speed="1">
							{content.details.description2}
						</p>
					</div>
				</Grid>

				<Grid item md={1}></Grid>
			</Grid>

			<ReactVisibilitySensor
				partialVisibility
				minTopValue={300}
				data-scroll
				data-scroll-speed="2"
				onChange={handleVisibilityChange}>
				<div>
					<StaticImage
						src="../../../../images/showcase/thng/showcase_votes_photo.jpg"
						alt=""
						className={classes.coverImage}
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						aria-hidden="true"
					/>
					<StaticImage
						src="../../../../images/showcase/thng/voted_white.svg"
						alt=""
						className={classes.votedWhite1}
						layout="constrained"
						loading="lazy"
						placeholder="none"
						aria-hidden="true"
					/>
					<StaticImage
						src="../../../../images/showcase/thng/voted_white.svg"
						alt=""
						className={classes.votedWhite2}
						layout="constrained"
						loading="lazy"
						placeholder="none"
						aria-hidden="true"
					/>
					<StaticImage
						src="../../../../images/showcase/thng/voted_white.svg"
						alt=""
						className={classes.votedWhite3}
						layout="constrained"
						loading="lazy"
						placeholder="none"
						aria-hidden="true"
					/>
					<StaticImage
						src="../../../../images/showcase/thng/voted_white.svg"
						alt=""
						className={classes.votedWhite4}
						layout="constrained"
						loading="lazy"
						placeholder="none"
						aria-hidden="true"
					/>

					<StaticImage
						src="../../../../images/showcase/thng/voted_black.svg"
						alt=""
						className={classes.votedBlack1}
						layout="constrained"
						loading="lazy"
						placeholder="none"
						aria-hidden="true"
					/>

					<StaticImage
						src="../../../../images/showcase/thng/voted_black.svg"
						alt=""
						className={classes.votedBlack2}
						layout="constrained"
						loading="lazy"
						placeholder="none"
						aria-hidden="true"
					/>
				</div>
			</ReactVisibilitySensor>
		</section>
	);
};

export default Vote;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.thngLightBlue,
		paddingTop: '3.625em',
		'@media (min-width: 600px)': {
			paddingTop: '5em',
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingBottom: '3em',
		paddingLeft: '1em',
		paddingRight: '1em',
	},
	header: {
		marginTop: '1.6875em',
		marginBottom: '1em',
		'@media (max-width: 599px)': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			marginBottom: '2em',
		},
	},
	subheader: {
		marginTop: '1.3333333333333333em',
		marginBottom: '0.5em',
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: '0.5em 0',
		'@media (max-width: 599px)': {
			margin: '1em 0',
		},
	},
	imageContainer: {
		position: 'relative',
		zIndex: 1,
		display: 'inline-block',
	},
	image: {
		'@media (min-width: 600px) and (max-width: 959px)': {
			// transform: 'translateX(-50%)',
		},
		'@media (max-width: 599px)': {
			transform: 'scale(1.25)',
			marginTop: '-50px',
			marginBottom: '50px',
		},
	},
	statisticCircle: {
		// @ts-ignore
		position: 'absolute !important',
		transform: 'translate(-135%, -5%)',
		zIndex: 0,
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			left: '50%',
			transform: 'translate(-25%, -10%)',
		},
		'@media (min-width: 960px)': {
			left: '50%',
			transform: 'translate(-115%, -15%)',
		},
	},
	statisticTable: {
		position: 'relative',
		transform: 'translate(-5em, -4em)',
		marginTop: '-309px',
		zIndex: 0,
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	coverImage: {
		width: '100%',
		maxHeight: '720px',
		objectFit: 'cover',
		objectPosition: 'right 8%',
		marginBottom: '-4px',
		'@media (min-width: 2400px)': {
			maHeight: '70vh',
		},
	},
	votedWhite1: {
		// @ts-ignore
		position: 'absolute !important',
		left: '25%',
		transform: 'translateY(75%)',
		transition: 'opacity 0.15s ease-in',
		transitionDelay: '0.1s',
		opacity: (props: ClassProps) => (props.visible ? 1 : 0),
		'@media (max-width: 599px)': {
			left: '5%',
			transform: 'translateY(50%)',
		},
	},
	votedWhite2: {
		// @ts-ignore
		position: 'absolute !important',
		left: '5%',
		transform: 'translateY(400%)',
		transition: 'opacity 0.2s ease-in',
		transitionDelay: '0.25s',
		opacity: (props: ClassProps) => (props.visible ? 1 : 0),
		'@media (max-width: 959px)': {
			transform: 'translateY(200%)',
		},
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	votedWhite3: {
		// @ts-ignore
		position: 'absolute !important',
		left: '28%',
		transform: 'translateY(550%)',
		transition: 'opacity 0.15s ease-in',
		transitionDelay: '0.45s',
		opacity: (props: ClassProps) => (props.visible ? 1 : 0),
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
	},
	votedWhite4: {
		// @ts-ignore
		position: 'absolute !important',
		left: '45%',
		transform: 'translateY(600%)',
		transition: 'opacity 0.1s ease-in',
		transitionDelay: '0.75s',
		opacity: (props: ClassProps) => (props.visible ? 1 : 0),
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
	},

	votedBlack1: {
		// @ts-ignore
		position: 'absolute !important',
		left: '35%',
		transform: 'translateY(200%)',
		transition: 'opacity 0.1s ease-in',
		transitionDelay: '0.35s',
		opacity: (props: ClassProps) => (props.visible ? 1 : 0),
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	votedBlack2: {
		// @ts-ignore
		position: 'absolute !important',
		left: '8%',
		transform: 'translateY(575%)',
		transition: 'opacity 0.15s ease-in',
		transitionDelay: '0.7s',
		opacity: (props: ClassProps) => (props.visible ? 1 : 0),
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
	},
});
